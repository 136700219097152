<script setup lang="ts">
const agentStore = useAgentsStore();
const { configState, agentGraph } = storeToRefs(agentStore);
</script>
<template>
  <div class="flex flex-col items-start gap-8">
    <AppInput
      v-model="configState.companyName"
      :option="agentGraph?.inputs?.companyName"
    />
    <AppInput
      v-model="configState.companyWebsite"
      :option="agentGraph?.inputs?.companyWebsite"
    />
    <AppInput
      v-model="configState.companyDescription"
      :option="agentGraph?.inputs?.companyDescription"
    />
    <AppInput
      v-model="configState.productOrService"
      :option="agentGraph?.inputs?.productOrService"
    />
    <AppInput
      v-model="configState.additionalCompanyInfo"
      :option="agentGraph?.inputs?.additionalCompanyInfo"
    />
  </div>
</template>
